import Home from './Components/Home.js';
import Contact from './Components/Contact.js';
import About from './Components/About.js';
import QrCode from './Components/QrCode.js';
import Store from './Components/Store.js';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createContext, useReducer} from "react";
import TutorialPage from './Components/Tutorial.js';
export const UserContext = createContext();

const Routing = () => {
  return (
   <>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/About" element={<About/>} />
        <Route exact path="/Contact" element={<Contact/>} />
        <Route exact path="/QrCode" element={<QrCode/>} />
        <Route exact path="/Store" element={<Store/>} />
        <Route exact path="/Tutorial" element={<TutorialPage/>} />
      </Routes>
   </>
  )
}
function App() {
 return (
<>
      <Router>
          <Routing/>
      </Router>
    </>)
}

export default App;