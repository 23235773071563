import React from 'react';
import Navbar from './Navbar.js';
import { useSpring, animated } from '@react-spring/web';
import Footer from './Footer.js';
import './store.css';

const products = [
  {
    name: "3 CURATIN TOUCH SWITCH",
    // description: "?????????",
    category: "2 MODULE",
    image: "./img/product-showcase/indiamart/2MODULE/3touch.jpeg",
    link: "https://www.indiamart.com/eston-automation/our-products.html"
  },
  {
    name: "2 TOUCH SWITCH",
    // description: "?????????",
    category: "2 MODULE",
    image: "./img/product-showcase/indiamart/2MODULE/2touch.jpeg",
    link: "https://www.indiamart.com/eston-automation/our-products.html"
  },
  
  {
    name: "4 TOUCH SWITCH",
    // description: "?????????",
    category: "4 MODULE",
    image: "./img/product-showcase/indiamart/4 MODULE/4touch.jpeg",
    link: "https://www.indiamart.com/eston-automation/our-products.html"
  },
  {
    name: "2 TOUCH SWITCH , 1(6AMP)SOCKET",
    // description: "?????????",
    category: "4 MODULE",
    image: "./img/product-showcase/indiamart/4 MODULE/2touch-1socket.jpeg",
    link: "https://www.indiamart.com/eston-automation/our-products.html"
  },
  {
    name: "4 TOUCH SWITCH , 1(6AMP)SOCKET",
    // description: "?????????",
    category: "4 MODULE",
    image: "./img/product-showcase/indiamart/4 MODULE/board_wall.jpeg",
    link: "https://www.indiamart.com/eston-automation/our-products.html"
  },
  {
    name: "4 TOUCH SWITCH 1FAN REGULATOR , 1(6AMP) SOCKET",
    // description: "?????????",
    category: "6 MODULE",
    image: "./img/product-showcase/indiamart/6 MODULE/board_green.jpeg",
    link: "https://www.indiamart.com/eston-automation/our-products.html"
  },
  {
    name: "4 TOUCH SWITCH, 2(6AMP)SOCKET",
    // description: "?????????",
    category: "6 MODULE",
    image: "./img/product-showcase/indiamart/6 MODULE/neon.png",
    link: "https://www.indiamart.com/eston-automation/our-products.html"
},
{
  name: "4 TOUCH SWITCH 1FANREGULATOR , 1(6AMP) SOCKET",
  category: "8 MODULE",
  // description: "don't trust on git",
  image: "./img/product-showcase/indiamart/8 MODULE/board_design.jpeg",
  link: "https://www.indiamart.com/eston-automation/our-products.html"
},
{
  name: "4 TOUCH SWITCH , 2(6AMP) SOCKET , 2PIN",
  category: "8 MODULE",
  // description: "don't trust on git",
  image: "./img/product-showcase/indiamart/8 MODULE/3scoket-2touch.jpeg",
  link: "https://www.indiamart.com/eston-automation/our-products.html"
},
{
  name: "12 TOUCH SWITCH 2FANREGULATOR , 2(6AMP)SOCKET",
  // description: "????",
  category: "12&16 MODULE",
  image: "./img/product-showcase/indiamart/16 MODULE/board_wood.jpeg",
  link: "https://www.indiamart.com/eston-automation/our-products.html"
},
  
  {
        name: "8 TOUCH SWITCH 2FAN REGULATOR ,2(6AMP)SOCKET",
        // description: "?????????",
        category: "12&16 MODULE",
        image: "./img/product-showcase/indiamart/12 MODULE/board_flower.jpeg",
        link: "https://www.indiamart.com/eston-automation/our-products.html"
  },
 

{
  name: "8 TOUCH SWITCH 2FAN REGULATOR ,2(6AMP)SOCKET",
  // description: "?????????",
  category: "12&16 MODULE",
  image: "./img/product-showcase/indiamart/12 MODULE/board_lights.jpeg",
  link: "https://www.indiamart.com/eston-automation/our-products.html"
},

];

const groupedProducts = products.reduce((acc, product) => {
  (acc[product.category] = acc[product.category] || []).push(product);
  return acc;
}, {});

const ProductsPage = () => {
  const fadeIn = useSpring({ opacity: 1, from: { opacity: 0 } });

  const handleProductClick = (link) => {
    window.open(link, "_blank", "noopener,noreferrer");
  };


  return (
     <div>
      <Navbar />
      <div className='pagestore'>
        <div className="row">
          <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
            <div className="section-title-2">
              <br />
              <h6 className="intro">Store</h6>
              <h2 className="title">Catalogue</h2>
            </div>
          </div>
        </div>
        <animated.div style={fadeIn} className="products-container">
          {Object.entries(groupedProducts).map(([category, products], index) => (
            <div key={index}>
              <h3 className="category-title">{category}</h3>
              <div className="category-container">
                {products.map((product, index) => (
                  <div
                    key={index}
                    className="product-card"
                    onClick={() => handleProductClick(product.link)}
                    style={{ cursor: 'pointer' }}
                  >
                    <img src={product.image} alt={product.name} className="product-image" />
                    <h5>{product.name}</h5>
                    <p>{product.description}</p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </animated.div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductsPage;