import React from 'react';
import './tutorial.css';
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import image1 from './tutorial-images/ok.png';
import image2 from './tutorial-images/2.png';
import image3 from './tutorial-images/3.png';
import image4 from './tutorial-images/4.png';
import image5 from './tutorial-images/5.png';
import image6 from './tutorial-images/6.png';
import image7 from './tutorial-images/7.png';
import image8 from './tutorial-images/8.png';
import image9 from './tutorial-images/9.png';
import image10 from './tutorial-images/10.png';
import image11 from './tutorial-images/11.png';
import image12 from './tutorial-images/12.png';
import image13 from './tutorial-images/13.png';
import image14 from './tutorial-images/14.png';
import image15 from './tutorial-images/15.png';

const categories = [
  { name: 'Step 1', images: [image1] },
  { name: 'Step 2', images: [image2]},
  { name: 'Step 3', images: [image3] },
  { name: 'Step 4', images: [image4] },
  { name: 'Step 5', images: [image5] },
  { name: 'Step 6', images: [image6] },
  { name: 'Step 7', images: [image7] },
  { name: 'Step 8', images: [image8] },
  { name: 'Step 9', images: [image9] },
  { name: 'Step 10', images: [image10] },
  { name: 'Step 11', images: [image11] },
  { name: 'Step 12', images: [image12] },
  { name: 'Step 13', images: [image13] },
  { name: 'Step 14', images: [image14] },
  { name: 'Step 15', images: [image15] },
];

const TutorialPage = () => {
  const scrollToCategory = (index) => {
    const categoryElement = document.getElementById(`category-${index}`);
    if (categoryElement) {
      categoryElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className='tutorial'>
        <Navbar></Navbar>
        <div className="section-title-2">
              <h6 className="title">Tutorial</h6>
            </div>
        
        {/* <nav>
        {categories.map((category, index) => (
          <button key={index} onClick={() => scrollToCategory(index)}>
            {category.name}
          </button>
        ))}
      </nav> */}
    <div className="tutorial-page">
    
      

      {categories.map((category, index) => (
        <div key={index} id={`category-${index}`} className="category">
          <h2>{category.name}</h2>
          <div className="image-container">
            {category.images.map((image, imgIndex) => (
              <div className="image-wrapper" key={imgIndex}>
                <img src={image} alt={`${category.name} - Image ${imgIndex + 1}`} />
              </div>
            ))}
          </div>
        </div>
      ))}

      <a href="https://youtu.be/1mMdMnEmFVc?si=9z69OU2MxeWVJluK" target="_blank" rel="noopener noreferrer">
        <div className="youtube-button">
          <i className="fab fa-youtube"></i> Watch on YouTube
        </div>
      </a>
      <a href="img/assets/tutorial.pdf" target="_blank" rel="noopener noreferrer">
        <div className="download-button">
        <i class="fa fa-download"></i> Download Manual  
        </div>
      </a>
    </div>
    <Footer></Footer>
    </div>
  );
};

export default TutorialPage;
