import React from 'react';
function Footer() {
  return (
   <>
   <footer className="footer2" style={{marginTop:100}}>
        <div className="home2-footer-container">
            <div className="home2-footer-banner-top-left wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="4s"><img src="img/product-showcase/footer-top-left.png" alt=""/></div>
            <div className="home2-footer-banner-top-right wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="7s"><img src="img/product-showcase/footer-top-right.png" alt=""/></div>
            <div className="home2-footer-banner-bototm-left wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="5s"><img src="img/product-showcase/footer-left-bottom.png" alt=""/></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2 col-12">
                        <div className="home2-footer-content">
                            <div className="logo">
                            <img src='img/assets/estonlogo.png' width="250px" height="250px" alt='EstonEdge'/>
                            </div>
                            <p className="text">Experience the future of home living with SmartHome. Our innovative solutions make your home smarter, safer, and more efficient. Connect with us and join the smart living revolution today!</p>
                            <ul className="social">
                                <li><a href="https://m.facebook.com/sumitpatel3180/"  target="_blank" rel="noopener noreferrer" ><i className="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/estonautomation" target='_blank' rel="noopener noreferrer" ><i className="fab fa-instagram"></i></a></li>
                                {/* <li><a href="#"><i className="fab fa-behance"></i></a></li>
                                <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li> */}
                                <li><a href="https://youtube.com/@estonautomation6787" target="_blank" rel="noopener noreferrer" >  <i className="fab fa-youtube"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="copyright">
                <p className="text">© 2017 EstonAutomation. All Rights Reserved.</p>
            </div>
        </div>
    </footer>
   
   </>
  );
}

export default Footer;