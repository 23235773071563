import React from "react";
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import {NavLink} from "react-router-dom";
// import {NavLink} from "react-router-dom";


function Home() {
  return (
    <>
    <Navbar></Navbar>
    <div>
        <section className="home2-hero-area">
            <div className="home2-hero-banner wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms"><img className="item-bounce" src="img/product-showcase/hero-banner.png" alt=""/></div>
            {/* <h1 className="home2-bg-text">Showcase</h1> */}
            <div className="home2-hero-container">
                <div className="row">
                    <div className="col-lg-5 col-md-7 col-12">
                        <div className="home2-hero-content">
                            <h4 className="intro">Smart Switch Board</h4>
                            <h2 className="title">Innovate Your Living Space.</h2>
                            <p className="text">Take control of your living space with cutting-edge smart home technology. Effortlessly manage your home appliances and more from the palm of your hand. Experience the ultimate convenience and security with SmartHome.</p>
                            <ul className="links">
                                <li><NavLink className="home2-button-2 link btn-hvr-anim-top" to="/Store">Buy Now</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="home2-feature-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 col-12">
                        <div className="section-title-2">
                            <h6 className="intro">Core Feautres</h6>
                            <h2 className="title">Discover the Future of Home Living</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <div className="home2-single-feature">
                            <div className="icon-box">
                                <span className="color-1 icon"><img src="img/assets/internet-of-things.png" alt=""  width="40" height="40"/></span>
                            </div>
                            <div className="content">
                                <h2 className="title">Quick Connect</h2>
                                <p className="text">Easily connect to any smart device with a single scan for seamless integration.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay="100ms" data-wow-duration="1500ms">
                        <div className="home2-single-feature">
                            <div className="icon-box">
                                <span className="color-2 icon"><img src="img/assets/worldwide-shipping.png" alt=""  width="40" height="40"/></span>
                            </div>
                            <div className="content">
                                <h2 className="title">Remote Control</h2>
                                <p className="text">Turn on or off any device from anywhere with just a tap on your smartphone.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div className="home2-single-feature">
                            <div className="icon-box">
                                <span className="color-3 icon"><img src="img/assets/time-management.png" alt=""  width="40" height="40"/></span>
                            </div>
                            <div className="content">
                                <h2 className="title">Auto Scheduling</h2>
                                <p className="text">Set schedules for automatic on/off times to fit your lifestyle effortlessly.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div className="home2-single-feature">
                            <div className="icon-box">
                                <span className="color-4 icon"><img src="img/assets/Smooth-experience.png" alt=""  width="40" height="40"/></span>
                            </div>
                            <div className="content">
                                <h2 className="title">Easeful Route</h2>
                                <p className="text">Experience effortless smart home management with our user-friendly interface.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="home2-built-with-area">
            <div className="home2-built-bg wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms"><img src="img/product-showcase/built-bg.png" alt=""/></div>
            <div className="home2-built-banner wow fadeInLeft animated" data-wow-delay="900ms" data-wow-duration="1500ms"><img className="item-bounce" src="img/product-showcase/built-banner.png"  width="510" height="500" alt=""/></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-12">
                        <div className="home2-built-with-content">
                            <div className="section-title-2">
                                <h6 className="intro">Download Now</h6>
                                <h2 className="title">Get the SmartHome App</h2>
                            </div>
                            <p className="text">Experience ultimate convenience by downloading the SmartHome app on Android or iOS. Instantly connect, control, and customize your smart devices from anywhere, making home automation easier than ever. Get the app and enhance your smart living experience!</p>
                            <ul className="download">
                                <li>
                                    <a href="https://play.google.com/store/apps/details?id=com.estonautomation.estonedge" target="_blank" rel="noopener noreferrer">
                                    <span className="left">
                                    <span className="icon"><i className="fab fa-android"></i></span>
                                    </span>
                                    <div className="right">
                                    <span className="intro">Download The App</span>
                                    <span className="title">Google Play</span>
                                    </div>
                                </a></li>
                                <li>
                                    <a href="https://apps.apple.com/in/app/estonedge/id6605929516" target="_blank" rel="noopener noreferrer">
                                    <span className="left">
                                    <span className="icon"><i className="fab fa-apple"></i></span>
                                    </span>
                                    <div className="right">
                                    <span className="intro">Get App</span>
                                    <span className="title">Apple Store</span>
                                    </div>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="home2-tutorial-area">
          <div className="home2-tutorial-container">
            <img src="img/product-showcase/Tech tutorial.png" alt="Tutorial Image" />
            <div className="home2-tutorial-content">
              <h2 className="title">Your Smart Home Guide</h2>
              <p className="text">Explore our easy-to-follow tutorials and master the features of your smart home system. Learn how to set up, customize, and get the most out of your devices with step-by-step guidance.</p>
              <a href="./Tutorial" className="home2-button-2 link btn-hvr-anim-top">Start Tutorial</a>
            </div>
          </div>
        </section>

        <section className="home2-experience-area">
            <div className="home2-experience-bg wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms"><img src="img/product-showcase/supported-bg.png" alt=""/></div>
            <div className="home2-experience-banner wow fadeInRight animated" data-wow-delay="900ms" data-wow-duration="1500ms"><img className="item-bounce" src="img/product-showcase/supported-banner.png" width="770" height="235" alt=""/></div>
            <div className="home2-experience-banner wow fadeInRight animated" data-wow-delay="900ms" data-wow-duration="1500ms"><img className="item-bounce" src="img/product-showcase/supported-banner.png" width="770" height="235" alt=""/></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="home2-expierince-content">
                            <div className="section-title-2">
                                <h6 className="intro">Automate Your Life</h6>
                                <h2 className="title">Innovative Solutions for Modern Homes</h2>
                            </div>
                            <p className="text">Transform your living space with cutting-edge technology designed to simplify and enhance your life. Our smart home solutions bring automation, efficiency, and security to your fingertips, making your home more comfortable and connected than ever before. Embrace the future of home living with innovations tailored to modern lifestyles.</p>
                            <div className="shop-price">
                                {/* <a href="#" className="shop home2-button-2"><span className="icon"><i className="fal fa-shopping-cart"></i></span> Go Shop</a> */}
                                {/* <p className="price">Price: <span className="color">$299.99</span></p> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
        
        <section className="home2-subscribe-area">
            <div className="home2-subscribe-container">
                <div className="home2-subscribe-top-banner wow pulse" data-wow-iteration="infinite" data-wow-duration="2.0s" data-wow-delay="0.5s"><img src="img/product-showcase/subscribe-banner-top.png" alt=""/></div>
                <div className="home2-subscribe-left-banner wow pulse" data-wow-iteration="infinite" data-wow-duration="2.0s" data-wow-delay="0.5s"><img src="img/product-showcase/subscribe-banner-left.png" alt=""/></div>
                <div className="home2-subscribe-right-banner"><img className="item-bounce" src="img/product-showcase/subscribe-banner-right.png" alt=""/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-12">
                            <div className="section-title-2 color-white">
                                <h6 className="intro">Eston  Automation</h6>
                                <h2 className="title">Effortless Control, <br/> Ultimate Convenienc</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    </div>
                </div>
            </div>
        </section>
        
        
        
        
    </div>
    <Footer></Footer>
    </>
  );
}

export default Home;
