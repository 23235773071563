
import React, { useState, useEffect } from 'react';
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import QRCode from 'qrcode.react';
import './qrcode.css'; 

const QrCode = () => {
    const [ssid, setSsid] = useState('');
    const [validationMessage, setValidationMessage] = useState('');
    const [showQRCode, setShowQRCode] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [isSSIDEditable, setIsSSIDEditable] = useState(true); 

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1024);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const validateSSID = (input) => {
        if (input.length > 12) {
            return 'SSID cannot exceed 12 characters.';
        }
        const regex = /^[A-Z0-9]*$/; // SSID should be alphanumeric and uppercase
        if (!regex.test(input)) {
            return 'Only uppercase letters and numbers are allowed.';
        }
        if (input.length > 0 && input.length < 12) {
            return 'SSID must be exactly 12 characters long.';
        }
        return ''; // Valid input
    };

    const handleChange = (e) => {
        const input = e.target.value.toUpperCase();
        setSsid(input);
        const message = validateSSID(input);
        setValidationMessage(message);
    };

    const handleGenerateQRCode = () => {
        if (!validationMessage) { // Only generate QR code if there is no validation error
            setShowQRCode(true);
            setIsSSIDEditable(false);
        }
    };

    const getQRCodeValue = () => {
        return `{"ssid" : "${ssid}" , "password" : "admin@123"}`;  
    };

    return (
        <div className='pageqrparent'>
            <Navbar />
            <div className='pageqr'>
                <div>
                    {isMobile ? (
                    <div className="mobile-view">
                        <div className="animatedDotone home2-footer-banner-bototm-left wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="5s"><img src="img/product-showcase/footer-left-bottom.png" alt=""/></div>
                        <br/><br/>
                        <div className="animatedDottwo home2-footer-banner-top-left wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="4s"><img src="img/product-showcase/footer-top-left.png" alt=""/></div>
                    </div>
                    ) : (
                    <div className="desktop-view">
                        <div className="animatedDot one home2-footer-banner-bototm-left wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="5s"><img src="img/product-showcase/footer-left-bottom.png" alt=""/></div>
                        <div ><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
                        <div className="animatedDot two home2-footer-banner-top-left wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="4s"><img src="img/product-showcase/footer-top-left.png" alt=""/></div>
                    </div>
                    )}
                </div>
                <div className="Qr-card">
                    <h2>Generate WiFi QR Code</h2>
                    <p>Create a QR code to easily connect to your WiFi network.</p>
                    
                    <div className="input-group">
                        <label>Network Name (SSID)</label>
                        {validationMessage && <p className="validation-message">{validationMessage}</p>}
                        <input 
                            type="text" maxLength={12}
                            placeholder="Enter your WiFi network name" 
                            value={ssid}
                            onChange={handleChange}
                            disabled={!isSSIDEditable} 
                        />
                    </div>
                    <button onClick={handleGenerateQRCode} className="generate-btn" disabled={!!validationMessage}>
                        Generate QR Code
                    </button>
                </div>
                {showQRCode && (
                    <div className="Qr-phone" >
                        <QRCode className="qrcode" value={getQRCodeValue()} size={137}/>
                        <img src="img/assets/iphone.png" alt="image1" style={{height:540, width:270}}/>
                    </div>)
                } 
                <div>
                    {isMobile ? (
                    <div className="mobile-view">
                        <div className="animatedDottwo home2-footer-banner-top-right wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="7s"><img src="img/product-showcase/footer-top-right.png" alt=""/></div>
                        <div className="animatedDotone home2-footer-banner-top-left wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="4s"><img src="img/product-showcase/footer-top-left.png" alt=""/></div>
                    </div>
                    ) : (
                    <div className="desktop-view">
                        <div className="animatedDot two home2-footer-banner-top-left wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="4s"><img src="img/product-showcase/footer-top-left.png" alt=""/></div>
                        <div ><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></div>
                        <div className="animatedDot one home2-footer-banner-top-right wow zoomIn animated" data-wow-delay="00ms" data-wow-iteration="infinite" data-wow-duration="7s"><img src="img/product-showcase/footer-top-right.png" alt=""/></div>
                    </div>
                    )}
                </div>
            </div>
            {/* <Footer/> */}
        </div>
    )
}

export default QrCode;
