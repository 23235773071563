import React from 'react'
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import { HiOutlineCube ,HiSun} from "react-icons/hi";
import { GiStarShuriken } from "react-icons/gi";
import { LiaStar,LiaStarHalfAltSolid,LiaStarSolid } from "react-icons/lia";

import './about.css';

function About() {
  return (
    <>
    <Navbar/>
    <section className="py-3 py-md-5">
      <div className="aboutpage container">
        <div className="row">
                  <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
                      <div className="section-title-2">
                        <br/>
                          <h6 className="intro">Information</h6>
                          <h2 className="title">About Us</h2><br/>
                          <p className="text">Discover how our passion for innovation drives us to enhance everyday life.</p>
                      </div>
                  </div>
        </div>
        <div className="aboutus-section">
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="aboutus">
                        <h2 className="aboutus-title">Eston Edge</h2>
                        <p className="aboutus-text">Take control of your living space with cutting-edge smart home technology. Effortlessly manage your home appliances and more from the palm of your hand. Experience the ultimate convenience and security with SmartHome.<br/> <br/>At SmartHome, we’re dedicated to transforming modern living with innovative technology. Our mission is to make your home smarter, more secure, and effortlessly connected. </p>
                        {/* <a className="aboutus-more" href="#">read more</a> */}
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="aboutus-banner">
                        <img src="http://themeinnovation.com/demo2/html/build-up/img/home1/about1.jpg" alt=""/>
                    </div>
                </div>
                <div className="col-md-5 col-sm-6 col-xs-12">
                    <div className="feature">
                        <div className="feature-box">
                            <div className="clearfix">
                                <div className="iconset">
                                    <span className="icon"><LiaStar style={{width:65, height:65}}/></span>
                                    
                                </div>
                                <div className="feature-content">
                                    <br/>
                                    <h4>Work with heart</h4>
                                    <p>Our commitment goes beyond technology—we put passion and care into every solution we create, ensuring your home is not just smart, but truly loved.</p>
                                </div>
                            </div>
                        </div>
                        <div className="feature-box">
                            <div className="clearfix">
                                <div className="iconset">
                                <span className="icon"><LiaStarHalfAltSolid style={{width:65, height:65}}/></span>
                                </div>
                                <div className="feature-content">
                                <br/>
                                    <h4>Reliable services</h4>
                                    <p>Depend on us for consistent, top-quality support and seamless smart home solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="feature-box">
                            <div className="clearfix">
                                <div className="iconset">
                                <span className="icon"><LiaStarSolid  style={{width:65, height:65}}/></span>
                                </div>
                                <div className="feature-content">
                                <br/>
                                    <h4>Great support</h4>
                                    <p>Count on us for reliable assistance whenever you need it. Our dedicated support team is here to ensure a smooth and seamless smart home experience.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default About;